export default {
  blogerName: 'AGRESSOR',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/Agressor_stream'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/watch?v=lzZc2tbpOJ8'
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/agressor_stream'
    },
    {
      name: 'kick',
      url: 'https://kick.com/agressor-streamer'
    }
  ],
  projects: [
    {
      name: 'gizbo',
      url: 'https://gizbo-way-two.com/cd2b18a89',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225%</strong><div>На первый депозит</div>',
    },
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/cd3b568e3',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c6e7af664',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c768eb10c',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/cf9ec22ab',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/cd88969cb',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c77589771',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c7234d88d',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c7b66eb4b',
      gameTitle: 'Izzi Art (BGaming)',
    },
  ],
  headerText: 'Регистрируйся с промокодом <strong>AGRESSOR</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'AGRESSOR',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
